import "./content.min.css";

const Content = () => (
  <div className="content">
    <div className="description">
      <div className="mainActivity">
        Ремонт квартир и офисов в Екатеринбурге
      </div>
      <div className="advantage">Качестово, гарантии, надёжность</div>
      <div className="specialConditions">
        Особые условия для жителей Академического
      </div>
    </div>
    <div className="contacts">
      <div className="phoneGroup">
        <img
          className="phoneIcon"
          src="./img/phone-call.svg"
          alt="the phone icon"
        />
        <img className="telegramIcon" src="./img/telegram.svg" alt="telegram" />
        <a className="phoneNumber" href="tel:+79521321118">
          +7(952)132-1118
        </a>
      </div>
      <div className="emailGroup">
        <img className="emailIcon" src="./img/email.svg" alt="email" />
        <a className="email" href="mailto:info@akadem1.ru">
          info@akadem1.ru
        </a>
      </div>
    </div>
  </div>
);

export default Content;
