import "./footer.min.css";

const Footer = () => (
  <footer>
    <div className="freepik pink">
      Icons made by{" "}
      <a
        href="https://www.flaticon.com/authors/freepik"
        title="Freepik"
        rel="noreferrer"
        target="_blank"
      >
        Freepik{" "}
      </a>{" "}
      from{" "}
      <a
        href="https://www.flaticon.com/"
        title="Flaticon"
        rel="noreferrer"
        target="_blank"
      >
        {" "}
        www.flaticon.com
      </a>
    </div>
    <div className="freepik pink">
      <a
        rel="noreferrer"
        target="_blank"
        href="https://icons8.com/icon/TCnKnYZFoOzM/телеграмма-app"
      >
        Телеграмма App
      </a>{" "}
      icon by{" "}
      <a rel="noreferrer" target="_blank" href="https://icons8.com">
        Icons8
      </a>
    </div>
    <div className="svyatoslavlp pink">
      Created by{" "}
      <a
        href="http://svyatoslavlp.ru/"
        title="svyatoslavlp"
        rel="noreferrer"
        target="_blank"
      >
        svyatoslavlp
      </a>
    </div>
  </footer>
);

export default Footer;
