import "./app.min.css";
import Header from "./components/Header/Header";
import Content from "./components/Content/Content";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <>
      <div id="to_expand">расширьте экран или уменьшите масштаб</div>
      <div className="container">
        <Header />
        <Content />
        <Footer />
      </div>
    </>
  );
}

export default App;
// function App() {
//   return (
//     <>
//       <Header />
//       <Main />
//       <Features />
//       <Footer />
//       {/* <Calendar /> */}
//       <Details />
//     </>
//   );
// }

// export default App;
